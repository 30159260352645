import { forwardRef } from 'react';
import { CheckboxProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    children,
    error,
    checkboxType,
    childrenWrapperClassName,
    innerWrapperClassName,
    squareClassName,
    isHideSquare,
    ...rest
  } = props;

  if (checkboxType === 'filled') {
    return (
      <label className={twMerge('flex flex-col', props.className)}>
        <div className={twMerge('flex items-center', innerWrapperClassName)}>
          <input
            type="checkbox"
            {...rest}
            ref={ref}
            className={clsx(
              'hidden',
              !isHideSquare &&
                '[&:checked+span]:bg-black [&:checked+span]:border-none'
            )}
          />
          {!isHideSquare && (
            <span
              className={twMerge(
                'shrink-0 border border-cerebral-grey hover:border-basalt-grey inline-block w-4 h-4 rounded-[.0625rem]',
                squareClassName
              )}
            ></span>
          )}
          {children && (
            <span className={twMerge('ml-3', childrenWrapperClassName)}>
              {children}
            </span>
          )}
        </div>

        {error && (
          <span className="w-full mt-1 text-sm text-left text-error inline-block">
            {error.message}
          </span>
        )}
      </label>
    );
  } else if (props.checkboxType === 'confirmCheckbox') {
    return (
      <label className={twMerge('flex flex-col', props.className)}>
        <div>
          <input
            type="checkbox"
            {...rest}
            ref={ref}
            className="hidden [&:checked+span]:bg-black [&:checked+span]:border-none"
          />
          <span className="border border-cerebral-grey hover:border-basalt-grey inline-block w-4 h-4 rounded-[.0625rem]"></span>
          {children && (
            <span className={twMerge('ml-3', props.textClassName)}>
              {children}
            </span>
          )}
        </div>
        {error && (
          <span className="w-full mt-1 text-sm text-left text-error inline-block">
            {error.message}
          </span>
        )}
      </label>
    );
  } else {
    return (
      <label className={twMerge('flex flex-col text-xs', props.className)}>
        <div className="flex items-start">
          <input
            type="checkbox"
            {...rest}
            ref={ref}
            className="w-4 h-4 shrink-0"
          />
          {children && (
            <span className={twMerge('ml-3', props.textClassName)}>
              {children}
            </span>
          )}
        </div>
        {error && (
          <span className="mt-1 text-sm text-error">{error.message}</span>
        )}
      </label>
    );
  }
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
