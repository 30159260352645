import clsx from 'clsx';
import { forwardRef, FocusEvent, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';

export const Input = forwardRef<
  HTMLInputElement,
  InputProps &
    Pick<
      NumberFormatProps,
      'format' | 'mask' | 'allowEmptyFormatting' | 'onValueChange'
    >
>((props, ref) => {
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const {
    id,
    label,
    labelStyle,
    error,
    mask,
    format,
    required = false,
    inputType,
    borderColor = 'black',
    errorClassName,
    preloadedData,
    isCreditCard,
    ...rest
  } = props;
  const hasFloatingLabel = label && labelStyle === 'floating';
  const hasinputType = label && inputType === 'materialInput';
  const inputClass = twMerge(
    clsx(
      'text-xs border px-2.5 h-10 placeholder:text-gray-600',
      'focus-visible:outline-none', // disable outline on focus
      { 'pt-1': hasFloatingLabel },
      error
        ? `border-error focus:border-error`
        : `border-cerebral-grey hover:border-[var(--border-color)] focus:border-[var(--border-color)]`
    ),
    props.className
  );
  const [isPreloaded, setIsPreloaded] = useState(false);

  useEffect(() => {
    if (preloadedData) {
      setIsPreloaded(true);
    }
  }, [preloadedData]);

  const inputProps: any = {
    id,
    ref,
    className: inputClass,
    onFocus: () => setFocused(true),
    onBlur: (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      setHasValue(!!event.target.value);

      // if inputType is materialInput, set isPreloaded to false when input is empty and not focused
      if (!event.target.value) {
        setIsPreloaded(false);
      }
    },
    style: { '--border-color': borderColor }
  };

  const Label = () => {
    if (!label) return null;

    return (
      <label
        htmlFor={id}
        className={clsx(
          'relative',

          {
            'absolute left-2.5 pointer-events-none transition-all transform -translate-y-1/2 text-xs text-basalt-grey':
              hasinputType
          },
          { 'mb-2 text-xs text-gray-800': !hasFloatingLabel },
          {
            'top-1/3 text-xs text-gray-800':
              hasFloatingLabel && (focused || hasValue)
          },
          { 'top-1/2': !(hasFloatingLabel && (focused || hasValue)) },
          {
            'absolute left-4 pointer-events-none transition-all mb-0 transform -translate-y-7 text-basalt-grey text-sm':
              hasFloatingLabel && inputType === 'materialInput'
          },
          {
            '-translate-y-[3.10rem] bg-white w-fit mb-0 text-basalt-grey text-[0.625rem] left-4 focused-on leading-[0.875rem] font-medium':
              (focused && inputType === 'materialInput') ||
              (hasValue && inputType === 'materialInput') ||
              (isPreloaded && inputType === 'materialInput')
          },
          {
            '-translate-y-[3.10rem] bg-white mb-0 text-[0.625rem] w-fit text-error left-4 leading-[0.875rem] font-medium':
              (!label && error && inputType) || (label && error && inputType)
          },
          {
            '!top-0 !-translate-y-12 bg-white w-fit mb-0 text-black text-[0.625rem] left-4 focused-on leading-[0.875rem] font-medium': isCreditCard
          }
        )}
      >
        {label} {required && <span className="text-secondary"></span>}
      </label>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="relative flex flex-col">
        {props.format ? (
          <>
            {labelStyle !== 'floating' && <Label />}
            <Controller
              name={props.name ?? ''}
              control={props.control}
              defaultValue={false}
              render={({ field }) => (
                <NumberFormat
                  format={format}
                  mask={mask ?? ''}
                  {...rest}
                  {...field}
                  {...inputProps}
                />
              )}
            />
            {labelStyle === 'floating' && <Label />}
          </>
        ) : (
          <>
            {labelStyle !== 'floating' && <Label />}
            <input {...rest} {...inputProps} />
            {labelStyle === 'floating' && <Label />}
          </>
        )}
      </div>
      {error && (
        <span className={`mb-3 text-sm text-error ${errorClassName}`}>
          {error.message}
        </span>
      )}
    </div>
  );
});

Input.displayName = 'Input';
