'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function CanonicalURL() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  // TODO: Update here When English is added
  const URL = "https://www.zsazsazsu.com.tr";
  const asPath = pathname === '/' ? '' : pathname;
  const currentPage = Number(searchParams.get('page')) || 1;
  const canonicalUrl =
    currentPage > 1
      ? `${(URL + asPath).split('?')[0]}?page=${currentPage}`
      : (URL + asPath).split('?')[0];

  useEffect(() => {
    const linkElement =
      document.querySelector("link[rel='canonical']") ||
      document.createElement('link');

    linkElement.setAttribute('rel', 'canonical');
    linkElement.setAttribute('href', canonicalUrl);

    if (!document.head.contains(linkElement)) {
      document.head.appendChild(linkElement);
    }
  }, [canonicalUrl]);

  return null;
}
