'use client';

import { ReactNode, useEffect, useState } from 'react';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type AccordionProps = {
  accordionType?: string;
  isExpandedProp?: boolean;
  title?: string;
  subTitle?: string;
  icons?: string[];
  iconSize?: number;
  iconColor?: string;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  dataTestId?: string;
  contentWrapperClassName?: string;
  lastChildBorder?: boolean;
  accordionId?: string;
  expandedAccordionId?: string;
  handleAccordionClick?: (id: string) => void;
};

export const Accordion = ({
  accordionType,
  isExpandedProp = false,
  title,
  subTitle,
  icons = ['chevron-up', 'chevron-down'],
  iconSize = 16,
  iconColor = 'fill-[#000000]',
  children,
  className,
  titleClassName,
  dataTestId,
  contentWrapperClassName,
  lastChildBorder = false,
  accordionId,
  expandedAccordionId,
  handleAccordionClick
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderAccordionIcon = () => {
    if (accordionType === 'faq') {
      if (isExpanded) {
        return (
          <Icon
            name="close"
            size={16}
            className="bg-white rounded-full p-2 lg:p-4"
          />
        );
      } else {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M20.25 4.5V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5Z"
              fill="black"
            />
            <path
              d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H12.75V20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H11.25V3.75C11.25 3.55109 11.329 3.36032 11.4697 3.21967C11.6103 3.07902 11.8011 3 12 3C12.1989 3 12.3897 3.07902 12.5303 3.21967C12.671 3.36032 12.75 3.55109 12.75 3.75V11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12Z"
              fill="#343330"
            />
          </svg>
        );
      }
    } else {
      return (
        <Icon
          name={isExpanded ? icons[0] : icons[1]}
          size={iconSize}
          className={clsx(
            `fill-[${iconColor}]}`,
            accordionType === 'footer' && 'bg-white bg-opacity-20 rounded-sm'
          )}
        />
      );
    }
  };

  const onClickAccordion = () => {
    if (handleAccordionClick && accordionId) {
      handleAccordionClick(accordionId);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (accordionId && expandedAccordionId) {
      setIsExpanded(accordionId === expandedAccordionId);
    } else {
      setIsExpanded(false);
    }
  }, [accordionId, expandedAccordionId]);

  return (
    <div
      className={twMerge(
        clsx(
          'flex flex-col justify-center border-b pb-4 mb-4',
          !lastChildBorder && 'last:border-none',
          accordionType === 'faq' &&
            isExpanded &&
            'bg-anemone-white border-none'
        ),
        className
      )}
    >
      <button
        className={clsx(
          'flex items-center cursor-pointer',
          accordionType === 'footer' ? 'gap-2' : 'justify-between'
        )}
        onClick={onClickAccordion}
        data-testid={dataTestId}
      >
        <div className="flex flex-col">
          {title && (
            <h3 className={twMerge('text-sm', titleClassName)}>{title}</h3>
          )}
          {subTitle && <h4 className="text-xs text-gray-700">{subTitle}</h4>}
        </div>

        {renderAccordionIcon()}
      </button>

      <div
        className={twMerge(
          `grid text-sm overflow-hidden transition-all duration-300 ease-in-out ${
            isExpanded
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          }`,
          contentWrapperClassName
        )}
      >
        <div className="overflow-hidden">
          <div className="py-3">{children}</div>
        </div>
      </div>
    </div>
  );
};
