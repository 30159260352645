'use client';

import { useLocalization } from '@akinon/next/hooks';
import { LocaleUrlStrategy } from '@akinon/next/localization';
import { urlLocaleMatcherRegex } from '@akinon/next/utils';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useEffect, useState } from 'react';

// For using style prop we need to extends React.HTMLAttributes<HTMLAnchorElement>
// To fix href prop collision we need to omit it from React.AnchorHTMLAttributes<HTMLAnchorElement>
interface LinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>, NextLinkProps{
  children: React.ReactNode;
  className?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export const Link = ({
  children,
  target,
  className,
  href,
  ...rest
}: LinkProps) => {
  const { locale, defaultLocaleValue, localeUrlStrategy } = useLocalization();
  const [formattedHref, setFormattedHref] = useState(href ?? '#');

  useEffect(() => {
    if (typeof href !== 'string') return;
    if (href.startsWith('http')) return;

    const pathnameWithoutLocale = href.replace(urlLocaleMatcherRegex, '');
    const hrefWithLocale = `/${locale}${pathnameWithoutLocale}`;

    if (localeUrlStrategy === LocaleUrlStrategy.ShowAllLocales) {
      setFormattedHref(hrefWithLocale);
    } else if (
      localeUrlStrategy === LocaleUrlStrategy.HideDefaultLocale &&
      locale !== defaultLocaleValue
    ) {
      setFormattedHref(hrefWithLocale);
    }
  }, [href, defaultLocaleValue, locale, localeUrlStrategy]);

  return (
    <NextLink
      href={formattedHref}
      target={target}
      className={className}
      {...rest}
    >
      {children}
    </NextLink>
  );
};
