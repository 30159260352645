'use client';

import ReactPortal from './react-portal';

import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';
import clsx from 'clsx';
import useWindowResize from '@theme/hooks/use-window-resize';

export interface ModalProps {
  isPortal?: boolean;
  portalId: string;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: React.ReactNode;
  showCloseButton?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  iconSize?: number;
  isMobile?: boolean;
  headerReverse?: boolean;
  titleClassName?: string;
  alignmentClasses?: string;
  animationDirectionList?: {
    mobile: 'bottom' | 'right';
    desktop: 'bottom' | 'right';
  };
  iconColor?: string;
  closeButtonOnClick?: () => void;
}

export const Modal = (props: ModalProps) => {
  const {
    isPortal = true,
    children,
    portalId,
    open,
    setOpen,
    title = '',
    showCloseButton = true,
    className,
    headerClassName,
    iconSize,
    isMobile = false,
    headerReverse,
    titleClassName,
    alignmentClasses,
    animationDirectionList,
    iconColor,
    closeButtonOnClick
  } = props;
  const { width } = useWindowResize();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  if (!open) return null;

  const handleClassList = (direction: 'right' | 'bottom', innerModal) => {
    if (direction === 'right') {
      innerModal.classList.add('animate-closing-from-right-modal');
      innerModal.classList.remove('animate-opening-from-right-modal');
    } else {
      innerModal.classList.add('animate-closing-from-bottom-modal');
      innerModal.classList.remove('animate-opening-from-bottom-modal');
    }
  };

  const handleAnimationClose = () => {
    const innerModal = document.getElementById(`inner-${portalId}`);
    if (innerModal) {
      if (width >= 1024) {
        handleClassList(animationDirectionList.desktop, innerModal);
      } else {
        handleClassList(animationDirectionList.mobile, innerModal);
      }
    }

    // 300 ms is the same duration as the closing animation
    setTimeout(() => setOpen(false), 300);
  };

  const modal = (
    <>
      <div
        onClick={() => {
          if (animationDirectionList) {
            handleAnimationClose();
          } else {
            setOpen(false);
          }
        }}
        className="fixed top-0 left-0 z-50 w-screen h-screen bg-primary bg-opacity-60"
      />
      <section
        id={`inner-${portalId}`}
        className={twMerge(
          'fixed z-50 bg-white',
          className,
          open && width < 1024 && animationDirectionList?.mobile === 'bottom'
            ? 'animate-opening-from-bottom-modal'
            : open && width < 1024 && animationDirectionList?.mobile === 'right'
            ? 'animate-opening-from-right-modal'
            : open &&
              width >= 1024 &&
              animationDirectionList?.desktop === 'bottom'
            ? 'animate-opening-from-bottom-modal'
            : open &&
              width >= 1024 &&
              animationDirectionList?.desktop === 'right'
            ? 'animate-opening-from-right-modal'
            : '',
          isMobile ? 'bottom-1/2' : 'top-1/2',
          alignmentClasses
            ? alignmentClasses
            : 'left-1/2 -translate-x-1/2 -translate-y-1/2'
        )}
      >
        {(showCloseButton || title) && (
          <div className={twMerge('flex px-8 py-8', headerClassName)}>
            {title && (
              <h3 className={twMerge('text-lg font-light', titleClassName)}>
                {title}
              </h3>
            )}
            {showCloseButton && (
              <button
                className={clsx('z-10', {
                  'ml-auto': !headerReverse,
                  'ml-0': headerReverse
                })}
                type="button"
                onClick={() => {
                  if (closeButtonOnClick !== undefined) {
                    closeButtonOnClick();
                  } else if (animationDirectionList) {
                    handleAnimationClose();
                  } else {
                    setOpen(false);
                  }
                }}
              >
                {iconColor ? (
                  <svg
                    width={iconSize ? iconSize : 17}
                    height={iconSize ? iconSize : 16}
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.50391 8.24609L0.634766 0.736328H4.42383L8.47656 5.99023L12.5488 0.736328H16.2793L10.3027 8.2168L16.4844 16H12.6855L8.33984 10.5117L3.89648 16H0.15625L6.50391 8.24609Z"
                      fill={iconColor}
                    />
                  </svg>
                ) : (
                  <Icon name="close" size={iconSize ? iconSize : 16} />
                )}
              </button>
            )}
          </div>
        )}
        {children}
      </section>
    </>
  );

  return (
    <>
      {isPortal ? (
        <ReactPortal wrapperId={portalId}>{modal}</ReactPortal>
      ) : (
        modal
      )}
    </>
  );
};
