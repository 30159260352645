import { useState, useEffect } from 'react';

function useWindowResize(debounceTime = 200) {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(
    isClient
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : {
          width: undefined,
          height: undefined,
        }
  );

  useEffect(() => {
    if (!isClient) {
      return;
    }

    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, debounceTime);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [debounceTime, isClient]);

  return windowSize;
}

export default useWindowResize;
