import { forwardRef } from 'react';
import { RadioProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';
import { Icon } from './icon';

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { children, ...rest } = props;

  if (props.radioButtonType == 'check') {
    return (
      <label className={twMerge('flex items-center text-xs', props.className)}>
        <input type="radio" {...rest} ref={ref} className="[&:checked+span_i]:block [&:checked+span]:bg-black [&:checked+span]:border-none hidden" />
        <span className='flex items-center justify-center w-6 h-6 border border-cerebral-grey hover:border-basalt-grey rounded-full'>
          <Icon name="check" size={12} className="hidden text-white" />
        </span>
        {children && <span className="text-xs ml-2">{children}</span>}
      </label>
    );
  } else {
    return (
      <label className={twMerge('flex items-center text-xs', props.className)}>
        <input
          type="radio"
          {...rest}
          ref={ref}
          className={twMerge('w-4 h-4', props.inputClassName)}
        />
        {children && <span className="text-xs ml-2">{children}</span>}
      </label>
    );
  }
});

Radio.displayName = 'Radio';

export { Radio };
