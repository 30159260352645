'use client';

import { useLocalization } from '@akinon/next/hooks';
import { Link } from '@theme/components';

interface LanguageSelectProps {
  className?: string;
}

export const LanguageSelect = (props: LanguageSelectProps) => {
  const { locale, locales, setLocale } = useLocalization();

  return (
    <div className={props.className} data-testid="language">
      {locales
        .slice()
        .reverse()
        .map((lang, index) => (
          <Link
            key={lang.value}
            href="#"
            onClick={() => {
              setLocale(lang.value);
            }}
            style={{
              textDecoration: locale === lang.value ? 'underline' : ''
            }}
            className={`language-selection lg:font-kronaOne lg:text-sm cursor-pointer lg:border-white border-black lg:pr-6 pr-3 max-lg:text-black text-white lg:leading-4 leading-5 !font-light text-base ${
              index === 0 ? 'lg:border-r-2 border-r-[1px]' : 'lg:pl-6 pl-3'
            }`}
          >
            {lang.label}
          </Link>
        ))}
    </div>
  );
};
